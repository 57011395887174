<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="canSave()"
        >
          <v-row>
            <!-- Nombre de la moneda -->
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="monedaNom"
                type="text"
                label="Nombre de moneda"
                dense
                outlined
                :rules="rules.required.concat([
                rules.requiredTrim(monedaNom), 
                rules.maxLength(monedaNom, 100)
                ])"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- se deshabilita esta opcion porque se implementa a futuro.
            Igual se envia al service en false por defecto. -->
            <!-- <v-col cols="6" md="6" class="py-0 my-n5 pb-4">
              <v-switch
                v-model="habCarga"
                label="Habilitado para carga"
              ></v-switch>
            </v-col> -->
            <v-col cols="6" md="6" class="py-0 my-n5 pb-4">
              <v-switch
                v-model="habListado"
                label="Habilitada para consulta"
              ></v-switch>
            </v-col>
          </v-row>
          <v-card-title  class="pl-1 pb-0 mb-3" style="font-size: 14px;">Vigencia de moneda por defecto</v-card-title>
          <v-row>
            <!-- Vigencia desde -->
            <v-col cols="6" md="6" sm="6" class="py-0">
              <v-menu
                ref="vigencia-desde"
                v-model="menuVigenciaDesde"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="vigenciaDesdeSelected"
                    label="Fecha desde"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    hint="Formato DD/MM/AAAA"
                    @blur="
                      vigenciaDesde = parseDateToIso(vigenciaDesdeSelected)
                    "
                    outlined
                    dense
                    clearable
                    v-mask="'##/##/####'"
                    v-on="on"
                    :rules="vigenciaDesde != null? 
                      rules.validDate.concat(
                        new Date(parseDateToIso(vigenciaDesdeSelected)) <=
                          new Date(parseDateToIso(vigenciaHastaSelected)) ||
                          'Formato incorrecto'
                      ) : []
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="vigenciaDesde"
                  no-title
                  @change="vigenciaDesdeSelected = formatDate(vigenciaDesde)"
                  @input="menuVigenciaDesde = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Vigencia hasta -->
            <v-col cols="6" md="6" sm="6" class="py-0">
              <v-menu
                ref="vigencia-hasta"
                v-model="menuVigenciaHasta"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="vigenciaHastaSelected"
                    label="Fecha hasta"
                    :append-icon="calendarIcon"
                    hint="Formato DD/MM/AAAA"
                    @blur="
                      vigenciaHasta = parseDateToIso(vigenciaHastaSelected)
                    "
                    outlined
                    autocomplete="not"
                    dense
                    clearable
                    v-mask="'##/##/####'"
                    v-bind="attrs"
                    v-on="on"
                    :rules="vigenciaHasta != null? 
                      rules.validDate.concat(
                        new Date(parseDateToIso(vigenciaDesdeSelected)) <=
                          new Date(parseDateToIso(vigenciaHastaSelected)) ||
                          'Formato incorrecto'
                      ) : []
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="vigenciaHasta"
                  no-title
                  @change="vigenciaHastaSelected = formatDate(vigenciaHasta)"
                  @input="menuVigenciaHasta = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
export default {
  name: "EditMoneda",
  directives: { mask },
  props: {
    editMonedaItem: {
      type: Object,
    },
  },
  data: () => ({
    rules: rules,
    formEditTitle: "Editar moneda",
    newTitle: "Nueva moneda",
    calendarIcon: enums.icons.CALENDAR,
    monedaNom: "",
    habCarga: false,
    habListado: false,
    menuVigenciaDesde: false,
    menuVigenciaHasta: false,
    vigenciaDesdeSelected: null,
    vigenciaHastaSelected: null,
    vigenciaDesde: null,
    vigenciaHasta: null,
    isFormValid: false,
  }),
  created() {
    if (this.editMonedaItem) {
      this.setMoneda(this.editMonedaItem);
    } else {
      this.newMoneda();
    }
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      saveMoneda: "AdministracionSistema/saveMoneda",
    }),
    setMoneda(data) {
      this.monedaNom = data.monedaNom;
      this.habCarga = data.habCarga;
      this.habListado = data.habListado;
      this.vigenciaDesdeSelected = data.vigDesde;
      this.vigenciaHastaSelected = data.vigHasta;
      this.vigenciaDesde = this.parseDateToIso(data.vigDesde);
      this.vigenciaHasta = this.parseDateToIso(data.vigHasta);
    },
    newMoneda() {
      this.formEditTitle = this.newTitle;
    },
    async canSave() {
      this.isFormValid = false;
      const data = {
        monedaId: this.editMonedaItem ? this.editMonedaItem.monedaId : 0,
        monedaNom: this.monedaNom,
        vigDesde: this.vigenciaDesde,
        vigHasta: this.vigenciaHasta,
        habCarga: this.habCarga,
        habListado: this.habListado,
      };
      try {
        const response = await this.saveMoneda(data);
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
          this.isFormValid = true;
        }
      } catch (error) {
        this.isFormValid = true;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
  },
};
</script>
<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>